.question-item {
    border: 1px solid #ccc;
    padding: 16px;
    margin-bottom: 16px;
}

@media (min-width: 992px) {
    .card.card-questionnaire {
        display: flex;
        flex-direction: row;
    }

    .card.card-questionnaire .card-body {
        width: 60%;
    }

    .card.card-questionnaire>.list-group {
        border-left: 1px solid var(--border);
        min-width: 40%;
        max-width: 40%;
    }

    /* .card.card-questionnaire>.list-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card.card-questionnaire>.list-group li {
        border: 0;
    } */
}