.recharts-wrapper {
    font-size: 14px;
}

.recharts-legend-wrapper {
    padding-left: 5px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 90%;
}

.table-widget-row {
    padding: 10px 16px;
    border-bottom: dashed #ddd 1px;
}

.table-widget-row:hover {
    background-color: #eee;
}

.table-widget-row:first-of-type {
    border-top: dashed #ddd 1px;
}

.recharts-legend-item-text {
    color: #000 !important;
}