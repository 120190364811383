.comment .fa-user {
    font-size: 32px;
    color: #666;
    line-height: 32px;
}

.comment-date {
    font-style: italic;
    font-size: 14px;
    line-height: 14px;
}

.comment-author {
    font-size: 14px;
    color: #666;
    line-height: 18px;
}

.comment-text {
    padding: 10px;
    background: #eee;
    border-radius: 5px;
    margin-top: 5px;
}

.comment-tools {
    display: none;
}

.comment:hover .comment-tools {
    display: block;
}