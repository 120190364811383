.card-transaction .list-group {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-transaction .list-group-flush>.list-group-item {
    border-bottom: 0;
    background: inherit;
}

.card-transaction-income {
    background-color: rgb(201, 255, 201);
}

.card-transaction-outcome {
    background-color: rgb(255, 210, 210);
}