.card-body-images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.card-body-images img {
    max-height: 150px;
    width: auto;
    margin: 0 5px 5px 0;
}

.post-images-editor {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}

.post-images-editor .post-image {
    position: relative;
}

.post-images-editor img {
    max-height: 130px;
    width: auto;
    margin: 0 5px 5px 0;
}

.post-images-editor .tools {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
}

.post-images-editor .post-image:hover .tools{
    display: block;
}

@media (min-width: 992px) {
    .card.card-post {
        display: flex;
        flex-direction: row;
    }

    .card.card-post .card-body {
        width: 60%;
    }

    .card.card-post>.list-group {
        border-left: 1px solid var(--border);
        min-width: 40%;
        max-width: 40%;
    }

    /* .card.card-post>.list-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card.card-post>.list-group li {
        border: 0;
    } */
}