
.card-incident .card-text span {
    font-weight: bold;
    color: var(--error);
}


@media (min-width: 992px) {
    .card.card-incident {
        display: flex;
        flex-direction: row;
    }

    .card.card-incident .card-body {
        width: 60%;
    }

    .card.card-incident>.list-group {
        border-left: 1px solid var(--border);
        min-width: 40%;
        max-width: 40%;
    }

    /* .card.card-incident>.list-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card.card-incident>.list-group li {
        border: 0;
    } */
}