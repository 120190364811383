.images-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
}

.images-list .post-image {
    position: relative;
}

.images-list img {
    max-height: 130px;
    width: auto;
    margin: 0 5px 5px 0;
}

.images-list .tools {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
}

.images-list .post-image:hover .tools{
    display: block;
}